import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { colors } from '@/styles/constant';

export enum TextInputNewSize {
  REGULAR = 'REGULAR',
  MEDIUM = 'MEDIUM',
}

export interface TextInputEventProps {
  name: string;
  value: any;
}

export interface TextInputNewProps {
  type?: string;
  size?: TextInputNewSize;
  name: string;
  label?: React.ReactNode;
  placeholder?: string;
  value: string;
  error?: any;
  helpMessage?: React.ReactNode;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  autoComplete?: boolean | string;
  onChange: ({ name, value }: TextInputEventProps) => void;
  onFocus?: () => void;
  onBlur?: ({ name, value }: TextInputEventProps) => void;
  onClickEnter?: () => void;
  children?: React.ReactNode;
}

const TextInputNew: React.FC<TextInputNewProps> = ({
  type = 'text',
  size = TextInputNewSize.MEDIUM,
  name,
  label,
  placeholder,
  value = '',
  error,
  helpMessage,
  isDisabled,
  isReadOnly,
  autoComplete,
  onChange,
  onBlur,
  onFocus,
  onClickEnter,
  children,
}) => {
  const getSizeStyle = TextInputNewSizeStyle[size];

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    onChange({ name, value });
  };
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      const value = e.target.value;
      onBlur({ name, value });
    }
  };

  return (
    <TextInputWrapStyle>
      {label && <strong>{label}</strong>}

      <TextInputGroupStyle>
        <TextInputStyle
          id={name}
          type={type}
          value={value}
          placeholder={placeholder}
          disabled={isDisabled}
          readOnly={isReadOnly}
          autoComplete={
            typeof autoComplete === 'string'
              ? autoComplete
              : autoComplete
              ? 'on'
              : 'off'
          }
          onChange={handleChange}
          onFocus={onFocus && onFocus}
          onBlur={handleBlur}
          onKeyUp={({ key }: { key: string }) =>
            key === 'Enter' && onClickEnter && onClickEnter()
          }
          // style
          showErr={!!error}
          rightEl={!!children}
          getSizeStyle={getSizeStyle}
        />

        <div>{children}</div>
      </TextInputGroupStyle>

      {(typeof error === 'string' || typeof error === 'object') && (
        <BottomTextStyle isErr>{error}</BottomTextStyle>
      )}
      {helpMessage && !error && (
        <BottomTextStyle>{helpMessage}</BottomTextStyle>
      )}
    </TextInputWrapStyle>
  );
};

export default TextInputNew;

export const TextInputNewSizeStyle: {
  [index: string]: SerializedStyles;
} = {
  [TextInputNewSize.REGULAR]: css`
    font-size: 14px;
    padding: 9px 16px;
  `,
  [TextInputNewSize.MEDIUM]: css`
    font-size: 16px;
    height: 50px;
    padding: 15px 20px;
  `,
};

const TextInputWrapStyle = styled.div`
  > strong {
    color: #646b79;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.57;
    margin-bottom: 5px;
  }
`;

const TextInputGroupStyle = styled.div`
  position: relative;

  > div {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translate(0, -50%);
  }
`;

const TextInputStyle = styled.input<{
  showErr?: boolean;
  rightEl?: boolean;
  getSizeStyle: SerializedStyles;
}>`
  align-items: center;
  background-color: ${colors.WHITE};
  border: solid 1px
    ${({ showErr }) => (showErr ? `${colors.RED_NEW} !important` : '#e1e1e1')};
  border-radius: 4px;
  color: ${colors.NAVY_NEW};
  display: flex;
  flex-direction: row;
  font-weight: 500;
  justify-content: flex-start;
  letter-spacing: normal;
  ${({ getSizeStyle }) => getSizeStyle};
  ${({ rightEl }) => rightEl && 'padding-right: 40px'};
  outline: 0 none;
  width: 100%;

  &:focus {
    border-color: ${colors.BROWN_DARK_NEW};
  }

  &::placeholder {
    color: #b1b5bc;
  }

  &:disabled {
    background-color: #f5f6f7;
    border-color: #e1e1e1 !important;
    color: #b1b5bc;
  }

  &:read-only {
    background-color: #f5f6f7;
    border-color: #e1e1e1 !important;
  }
`;

const BottomTextStyle = styled.p<{ isErr?: boolean }>`
  color: ${({ isErr }) => (isErr ? colors.RED_NEW : '#646b79')};
  font-size: 12px;
  line-height: 1.67;
  margin: 12px 0 0;

  a {
    color: ${({ isErr }) => (isErr ? colors.RED_NEW : '#646b79')};
    font-size: 12px;
    font-weight: bold;
    line-height: 1.67;
  }
`;
